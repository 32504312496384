<template>
    <div class="margin-bottom-30 note-wrapper">
        <div class="note-container">
            <ViewTitle title="備註" class="content-viewtitle" />
            <div
                class="textarea"
                :class="{ placeholder: !remark }"
                v-text="remark || '無備註'"
            />
        </div>
        <div class="note-container">
            <ViewTitle title="記事" class="content-viewtitle">
                <template v-slot:buttons>
                    <Button
                        v-if="$hasPermission('editInfo')"
                        buttonWord="編輯"
                        buttonStyle="hollow"
                        buttonSize="small"
                        @click="turnOnModal"
                    />
                </template>
            </ViewTitle>
            <textarea class="textarea" v-text="note" readonly />
        </div>
    </div>
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'InsuranceRemarkNote',
    components: {
        ViewTitle,
        Button
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object,
            default: function () {
                return { isShow: false }
            }
        },
        insId: {
            type: String,
            default: function () {
                return ''
            }
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        turnOnModal: function () {
            if (!this.$hasPermission('editInfo')) return
            this.$emit('update:modelValue', { isShow: true, key: 'note' })
        }
    },
    watch: {
        dataList: function () {
            this.remark = this.dataList.remark
            this.note = this.dataList.note
        }
    },
    data() {
        return {
            isShowModal: false,
            isShowSuccessModal: false,
            remark: this.dataList.remark,
            note: this.dataList.note,
            isSaveLoading: false
        }
    }
}
</script>

<style lang="scss" scoped>
.note-wrapper {
    display: flex;
    .note-container {
        width: 100%;
        margin-bottom: 30px;
        &:first-of-type {
            margin-right: 30px;
            @media screen and (max-width: 576px) {
                margin-right: 0;
            }
        }
        .textarea {
            height: 200px;
            border: none;

            &:focus {
                border: none;
            }

            @media screen and (max-width: 576px) {
                height: 100px;
            }
        }
    }
    @media screen and (max-width: 576px) {
        display: block;
    }
}

.content-viewtitle {
    @media screen and (max-width: 576px) {
        padding: 0 0 10px 0;
        :deep(.main-header-word) {
            font-size: 20px;
        }
    }
}

.textarea {
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid $fourth-grey;
    font-size: 14px;
    font-family: $primary-cn;
    color: $sixth-black;
    background-color: $primary-white;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    resize: none;

    &.placeholder {
        color: $placeholder-black;
    }

    &:focus {
        border: 1px solid $third-blue;
    }
}

::-webkit-input-placeholder {
    color: $placeholder-black;
}

:-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-moz-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::-ms-placeholder {
    color: $placeholder-black;
    opacity: 1;
}

::placeholder {
    color: $placeholder-black;
}

.loading {
    position: relative;
    width: 68px;
    .save-button {
        visibility: hidden;
    }
    .process-loading-icon {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 8px;
        right: 25px;
    }
}
</style>
